<template>
  <div class="statistics">
    <div class="title">销售统计</div>
    <div class="nav">
      <div class="nav_box">
        <div class="nav_title">
          总销售额
          <el-tooltip
            class="item"
            effect="dark"
            content="总销售额：当前讲师所有课程的销售总金额"
            placement="top-start"
          >
            <i class="el-icon-warning icon_nav"></i>
          </el-tooltip>
        </div>
        <div class="nav_nub">￥{{ nav.sumOrderAmount }}</div>
      </div>
      <div class="nav_box">
        <div class="nav_title">
          日均销售额<el-tooltip
            class="item"
            effect="dark"
            content="日均销售额：总销售额/销售天数"
            placement="top-start"
          >
            <i class="el-icon-warning icon_nav"></i>
          </el-tooltip>
        </div>
        <div class="nav_nub">￥{{ nav.dayAmount }}</div>
      </div>
      <div class="nav_box">
        <div class="nav_title">
          浏览量<el-tooltip
            class="item"
            effect="dark"
            content="浏览量：用户浏览的总数，进入课程详情页即算一次浏览记录"
            placement="top-start"
          >
            <i class="el-icon-warning icon_nav"></i>
          </el-tooltip>
        </div>
        <div class="nav_nub">{{ nav.views }}</div>
      </div>
      <div class="nav_box">
        <div class="nav_title">
          支付笔数<el-tooltip
            class="item"
            effect="dark"
            content="支付笔数：支付成功的订单数量"
            placement="top-start"
          >
            <i class="el-icon-warning icon_nav"></i>
          </el-tooltip>
        </div>
        <div class="nav_nub">{{ nav.paySum }}</div>
      </div>
    </div>
    <div class="main">
      <el-date-picker
        v-model="date"
        class="date_icon"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        value-format="yyyy-MM-dd"
        @change="onDate"
      >
      </el-date-picker>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="销售额" name="first">
          <div
            ref="timeChart"
            class="timeChart"
            style="width:120%;height:450px;margin-left:-135px"
          ></div>
        </el-tab-pane>
        <el-tab-pane label="浏览量" name="second">
          <div
            ref="timeChart1"
            class="timeChart"
            style="width:120%;height:450px;margin-left:-135px"
          ></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
const echarts = require('echarts')
import {
  findTeacherOrderStatistic,
  teacherMonthStatistic,
  teacherStatisticProductViews,
} from '@/api/teacher'
import to from 'await-to'
export default {
  components: {},
  data() {
    return {
      nav: {},
      activeName: 'first',
      date: '',
      statistic: [],
      views: [],
      timeBegin: '',
      timeEnd: '',
    }
  },
  mounted() {
    this.findTeacherOrderStatistic()
    this.teacherMonthStatistic()
  },
  methods: {
    async findTeacherOrderStatistic() {
      const [res, err] = await to(findTeacherOrderStatistic())
      if (err) return this.$message.warning(err.msg)
      this.nav = res.data
    },
    async teacherMonthStatistic() {
      const [res, err] = await to(
        teacherMonthStatistic({ month: 0, timeBegin: this.timeBegin, timeEnd: this.timeEnd }),
      )
      if (err) return this.$message.warning(err.msg)
      this.statistic = res.data
      this.$nextTick(() => {
        let timeChart = echarts.init(this.$refs.timeChart)
        var option5 = {
          color: ['#82BDFF'],
          xAxis: {
            type: 'category',
            data: this.statistic.map(item => item.month),
          },
          yAxis: {
            type: 'value',
            name: '单位/元',
            nameGap: 35,
          },
          series: [
            {
              data: this.statistic.map(item => item.amount),
              type: 'bar',
              barWidth: 80,
            },
          ],
        }
        timeChart.setOption(option5)
      })
    },
    async teacherStatisticProductViews() {
      const [res, err] = await to(
        teacherStatisticProductViews({
          month: 0,
          timeBegin: this.timeBegin,
          timeEnd: this.timeEnd,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.views = res.data
      this.$nextTick(() => {
        let timeChart1 = echarts.init(this.$refs.timeChart1)
        var option6 = {
          color: ['#82BDFF'],
          xAxis: {
            type: 'category',
            data: this.views.map(item => item.month),
          },
          yAxis: {
            type: 'value',
            name: '单位/次',
            nameGap: 35,
          },
          series: [
            {
              data: this.views.map(item => item.views),
              type: 'bar',
              barWidth: 80,
            },
          ],
        }
        timeChart1.setOption(option6)
      })
    },
    onDate() {
      this.timeBegin = this.date[0]
      this.timeEnd = this.date[1]
      if (this.activeName == 'first') {
        this.teacherMonthStatistic()
      }
      if (this.activeName == 'second') {
        this.teacherStatisticProductViews()
      }
    },
    handleClick() {
      this.date = ''
      this.timeBegin = ''
      this.timeEnd = ''
      if (this.activeName == 'first') {
        this.teacherMonthStatistic()
      }
      if (this.activeName == 'second') {
        this.teacherStatisticProductViews()
      }
    },
  },
}
</script>
<style lang="scss" scope>
.statistics {
  background-color: #fff;
  padding: 0 20px;
  height: 800px;
}
.title {
  height: 58px;
  line-height: 58px;
  color: #333333;
  font-size: 18px;
}
.nav {
  display: flex;
  justify-content: space-between;
  .nav_box {
    width: 360px;
    height: 116px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 30px;
    box-sizing: border-box;
  }
  .nav_title {
    font-size: 14px;
    color: #666666;
    .icon_nav {
      color: #e0e0e0;
      margin-left: 3px;
    }
  }
  .nav_nub {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
  }
}
.main {
  position: relative;
  height: 500px;
  background-color: #fcfbfa;
  margin: 40px 0 40px 0;
  padding: 30px 20px;
}
.date_icon {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 9999;
}
</style>
